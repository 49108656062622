import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class AgentService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public getIdentificationsFromJson(data) {
    const identifications = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { identifications, pagination }
  }

  public async createSkills(agent_skill) {
    const { data } = await this.httpClient.post('/agent/api/agent_skills', {
      agent_skill,
    })
    const createdSkills = this.serializer.parseResourceData(data.agent_skill, data.agent_skill.data)

    return { createdSkills, flush: data.flush }
  }
  public async updateSkills(agent_skill) {
    const { data } = await this.httpClient.patch(`/agent/api/agent_skills/${agent_skill.id}`, {
      agent_skill,
    })
    const updatedSkills = this.serializer.parseResourceData(data.agent_skill, data.agent_skill.data)

    return { updatedSkills, flush: data.flush }
  }
  public async deleteSkills(id) {
    return this.httpClient.delete(`/agent/api/agent_skills/${id}`)
  }

  public async createTourSkills(tourSkills) {
    const { data } = await this.httpClient.post('/agent/api/agent_tour_skills', {
      agent_tour_skill: tourSkills,
    })
    const createdTourSkills = this.serializer.parseResourceData(
      data.agent_tour_skill,
      data.agent_tour_skill.data
    )

    return { createdTourSkills, flush: data.flush }
  }

  public async updateTourSkills(tourSkills) {
    const { data } = await this.httpClient.patch(`/agent/api/agent_tour_skills/${tourSkills.id}`, {
      agent_tour_skill: tourSkills,
    })
    const updatedTourSkills = this.serializer.parseResourceData(
      data.agent_tour_skill,
      data.agent_tour_skill.data
    )

    return { updatedTourSkills, flush: data.flush }
  }

  public async deleteTourSkills(id) {
    return this.httpClient.delete(`/agent/api/agent_tour_skills/${id}`)
  }

  public async agentSkillAssign(param) {
    const { data } = await this.httpClient.post(`/agent/api/agent_guide_lists/agent_skill_assign`, {
      skill_assign: param,
    })

    return { flush: data.flush }
  }
  public async agentTourSkillAssign(param) {
    const { data } = await this.httpClient.post(
      `/agent/api/agent_guide_lists/agent_tour_skill_assign`,
      {
        skill_assign: param,
      }
    )

    return { flush: data.flush }
  }
  public async agentSkillUnassign(param) {
    const { data } = await this.httpClient.put(`/agent/api/agent_guide_lists/agent_skill_delete`, {
      skill_assign: param,
    })

    return { flush: data.flush }
  }
  public async agentTourSkillUnassign(param) {
    const { data } = await this.httpClient.put(
      `/agent/api/agent_guide_lists/agent_tour_skill_delete`,
      {
        skill_assign: param,
      }
    )

    return { flush: data.flush }
  }

  public async removeGuideFromChat(reservation_id, guide_id) {
    const { data } = await this.httpClient.get(
      `/api/reservations/${reservation_id}/remove_from_chat/${guide_id}`
    )

    return { data }
  }

  // 打診
  public async createSoundOutTemplate(sound_out_template) {
    const { data } = await this.httpClient.post(`/agent/api/sound_out_templates`, {
      sound_out_template,
    })
    const createdTemplate = this.serializer.parseResourceData(
      data.sound_out_template,
      data.sound_out_template.data
    )

    return { createdTemplate, flush: data.flush }
  }

  public async updateSoundOutTemplate(id, sound_out_template) {
    const { data } = await this.httpClient.put(`/agent/api/sound_out_templates/${id}`, {
      sound_out_template,
    })
    const updatedSoundOutTemplate = this.serializer.parseResourceData(
      data.sound_out_template,
      data.sound_out_template.data
    )

    return { updatedSoundOutTemplate, flush: data.flush }
  }

  public async deleteSoundOutTemplate(id) {
    const { data } = await this.httpClient.delete(`/agent/api/sound_out_templates/${id}`)

    return { flush: data.flush }
  }

  // 正式依頼
  public async createFormalRequestTemplate(formal_request_template) {
    const { data } = await this.httpClient.post(`/agent/api/formal_request_templates`, {
      formal_request_template,
    })
    const createdTemplate = this.serializer.parseResourceData(
      data.formal_request_template,
      data.formal_request_template.data
    )

    return { createdTemplate, flush: data.flush }
  }

  public async updateFormalRequestTemplate(id, formal_request_template) {
    const { data } = await this.httpClient.put(`/agent/api/formal_request_templates/${id}`, {
      formal_request_template,
    })
    const updatedFormalRequestTemplate = this.serializer.parseResourceData(
      data.formal_request_template,
      data.formal_request_template.data
    )

    return { updatedFormalRequestTemplate, flush: data.flush }
  }

  public async deleteFormalRequestTemplate(id) {
    const { data } = await this.httpClient.delete(`/agent/api/formal_request_templates/${id}`)

    return { flush: data.flush }
  }

  // サンクスメール
  public async createMailTemplate(mail_template) {
    const { data } = await this.httpClient.post(`/agent/api/mail_templates`, { mail_template })
    const createdMailTemplate = this.serializer.parseResourceData(
      data.mail_template,
      data.mail_template.data
    )

    return { createdMailTemplate, flush: data.flush }
  }

  public async updateMailTemplate(id, mail_template) {
    const { data } = await this.httpClient.put(`/agent/api/mail_templates/${id}`, { mail_template })
    const updatedMailTemplate = this.serializer.parseResourceData(
      data.mail_template,
      data.mail_template.data
    )

    return { updatedMailTemplate, flush: data.flush }
  }

  public async deleteMailTemplate(id) {
    const { data } = await this.httpClient.delete(`/agent/api/mail_templates/${id}`)

    return { flush: data.flush }
  }

  public async updateReservation(reservation: any, isReservation: boolean) {
    const { id } = reservation
    const param = isReservation
      ? { reservation }
      : {
          direct_reservation: { ...reservation },
        }

    const { data } = await this.httpClient.patch(`/agent/api/reservations/${id}`, param)

    const updatedReservation = this.serializer.parseResourceData(
      data.reservation,
      data.reservation.data
    )

    return { updatedReservation, flush: data.flush }
  }

  public async getContracts(params) {
    const { data } = await this.httpClient.get(`/agent/api/contracts/`, {
      params,
    })
    return { contractsData: data.contracts }
  }

  public async getTemplates() {
    const templates = await this.httpClient.get(`/agent/api/contracts/templates`, {})
    return templates.data.templates
  }

  public async createContract(params) {
    const { data } = await this.httpClient.post(`/agent/api/contracts`, {
      params,
    })
    return { data, flush: data.flush }
  }

  public async updateAgentRanks(rank_ids) {
    const { data } = await this.httpClient.patch(`/agent/api/agent_ranks`, { rank_ids })
    return { agentRanks: data.agent_ranks, flush: data.flush }
  }

  public async updateAgentGuide(id, formData) {
    const { data } = await this.httpClient.patch(`/agent/api/agent_guides/${id}`, formData)
    return { agentRanks: data.agent_ranks, flush: data.flush }
  }

  public async updatePayment(payment) {
    const { data } = await this.httpClient.patch(`/agent/api/payments/${payment.id}`, {
      payment,
    })
    const updatedPayment = this.serializer.parseResourceData(data.payment, data.payment.data)

    return { updatedPayment, flush: data.flush }
  }

  public async createPaymentItem(tourGroup) {
    const { data } = await this.httpClient.post(`/agent/api/payment_items`, {
      payable_id: tourGroup.id,
      payable_type: 'TourGroup',
    })
    const createdPaymentItem = this.serializer.parseResourceData(
      data.payment_item,
      data.payment_item.data
    )

    return { createdPaymentItem, flush: data.flush }
  }

  public async updatePaymentItem(payment_item) {
    const { data } = await this.httpClient.patch(`/agent/api/payment_items/${payment_item.id}`, {
      payment_item,
    })
    const updatedPaymentItem = this.serializer.parseResourceData(
      data.payment_item,
      data.payment_item.data
    )

    return { updatedPaymentItem, flush: data.flush }
  }

  public async sendPaymentNotification(payment) {
    const { data } = await this.httpClient.post(
      `/agent/api/payments/${payment.id}/send_notification`
    )
    return { updatedPayment: data.payment, flush: data.flush }
  }

  public async sendPaymentConfirmation(payment_ids) {
    const { data } = await this.httpClient.patch(`/agent/api/payments/send_confirmation`, {
      payment_ids,
    })
    return { payments: data.payments, flush: data.flush }
  }
  
  public async downloadSpreadSheetPayments(guides, month, share, issue_date) {
    const { data } = await this.httpClient.post(`/power_user/api/payments/download`, {
      guides: guides,
      month: month,
      share: share,
      issue_date: issue_date
    })
    return { flush: data.flush }
  }

  public async postFormalRequests(formData) {
    const { data, status } = await this.httpClient.post(`/agent/api/formal_requests`, formData)

    return { flush: data.flush, status }
  }

  public async postSoundOuts(formData) {
    const { data, status } = await this.httpClient.post(`/agent/api/sound_outs`, formData)

    return { flush: data.flush, status }
  }

  public async tourGuideCancel(id, formal_request) {
    const { data } = await this.httpClient.put(`/agent/api/formal_requests/${id}/cancel`, {
      formal_request,
    })
    return {
      tourGuide: data.tour_guide,
      tourGuideMessage: data.tour_guide_message,
      flush: data.flush,
    }
  }
}

export default AgentService
