import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class PostService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public getPostFromJson(data) {
    const post = this.serializer.parseResourceData(data, data.data)

    return { post }
  }

  public getPostsFromJson(data) {
    const posts = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { posts, pagination }
  }

  public async toggleLike(post) {
    const { data } = await this.httpClient.put(`/api/posts/${post.id}/toggle_like`)

    return { like: data.like }
  }

  public async create(params) {
    const { data } = await this.httpClient.post('/api/posts', { post: params })
    const { edit_post_path } = data
    const post = this.serializer.parseResourceData(data.post, data.post.data)

    return { post, edit_post_path }
  }

  public async update(params) {
    const { data } = await this.httpClient.patch(`/api/posts/${params.id}`, { post: params })
    const { flush } = data
    const post = this.serializer.parseResourceData(data.post, data.post.data)

    return { post, flush }
  }

  public async updatePostDetail(params) {
    const { data } = await this.httpClient.patch(`/api/post_details/${params.id}`, {
      post_detail: params,
    })
    const { flush } = data
    const postDetail = this.serializer.parseResourceData(data.post_detail, data.post_detail.data)

    return { postDetail, flush }
  }
  public async updateSlug(params) {
    const { data } = await this.httpClient.put(`/api/posts/${params.id}/update_slug`, {
      post: params,
    })
    const { flush } = data
    const post = this.serializer.parseResourceData(data.post, data.post.data)

    return { post, flush }
  }

  public async publish(params) {
    const { data } = await this.httpClient.put(`/api/posts/${params.id}/publish`, {})
    const { post_path } = data

    return { post_path }
  }

  public async suspend(params) {
    const { data } = await this.httpClient.put(`/api/posts/${params.id}/suspend`, {})
    const { flush, post, post_path } = data

    return { flush, post, post_path }
  }

  public async search(params) {
    const { data } = await this.httpClient.get('/api/posts/search', { params })
    const posts = this.serializer.parseResourceData(data.posts.posts, data.posts.posts.data)
    const pagination = data.posts.posts.meta

    return { posts, pagination }
  }

  public async searchOwnPost(params) {
    const { data } = await this.httpClient.get('/api/posts/search_from_own', { params })
    const posts = this.serializer.parseResourceData(data.posts.posts, data.posts.posts.data)
    const pagination = data.posts.posts.meta

    return { posts, pagination }
  }

  public async getFavorites(params) {
    const { data } = await this.httpClient.get('/api/favorites', { params })
    const posts = this.serializer.parseResourceData(data.posts.posts, data.posts.posts.data)
    const pagination = data.posts.posts.meta

    return { posts, pagination }
  }

  public async createCollectionCalendar(post_id, calendar_attributes) {
    const formData = {
      post: { calendar_attributes },
    }
    const { data } = await this.httpClient.post(
      `/api/posts/${post_id}/collection_calendars`,
      formData
    )

    return { flush: data.flush }
  }

  public async getCalendarCollections(post_id, month) {
    const { data } = await this.httpClient.get(`/api/posts/${post_id}/calendars`, {
      params: { month },
    })
    const calendarCollections = this.serializer.parseResourceData(
      data.calendars,
      data.calendars.data
    )
    return { calendarCollections }
  }

  public async getCalculation(post_id, params) {
    const { data } = await this.httpClient.get(`/api/posts/${post_id}/calculate`, { params })

    return { price: data.price }
  }
  public async getChargePrice(params) {
    const { data } = await this.httpClient.post(`/api/check_coupon_validity`, { ...params })
    const { charge_price, flush } = data

    return { charge_price, flush }
  }
  public async getRelatedPosts(post) {
    const { data } = await this.httpClient.get(`/api/related_posts/${post.id}`)
    const relatedPosts = this.serializer.parseResourceData(data.posts, data.posts.data)

    return { relatedPosts }
  }

  public async getBokunActivities() {
    const { data } = await this.httpClient.get(`/api/bokun_activities`)
    return { data }
  }

  public async createReview(formData) {
    return this.httpClient.post(`/api/reviews`, formData)
  }

  public async createReviewForDirectReservation(formData) {
    return this.httpClient.post(`/api/reviews/create_for_non_signed_in`, formData)
  }

  public async createReviewForTokenIssuedLink(formData) {
    return this.httpClient.post(`/api/reviews/create_for_token_issued_link`, formData)
  }

  public async createReviewReply(params) {
    const { data } = await this.httpClient.post(`/api/review_replies`, params)

    return { reviewReply: data }
  }

  public async createPostRankFees(post_id, params) {
    const { data } = await this.httpClient.post(
      `/api/posts/${post_id}/collection_post_rank_fees`,
      params
    )
    const { flush } = data
    const post_rank_fees = this.serializer.parseResourceData(
      data.post_rank_fees,
      data.post_rank_fees.data
    )
    return { post_rank_fees, flush }
  }
}

export default PostService
