import * as React from 'react'
import styled from 'styled-components'
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET_MOBILE,
  BREAKPOINT_TABLET_SMALL,
  COLORS,
} from 'static/constants'

interface IPanelProps {
  title?: string | React.ReactElement
  className?: string
  headerImage?: string
  headerOpacity?: boolean
  noDecoration?: boolean
  marginType?: 'dashboard' | 'default'
}

const Panel: React.FC<IPanelProps> = ({ marginType = 'default', ...props }) => {
  return (
    <PanelWrapper
      className={`Panel ${marginType} ${props.className || ''}`}
      noDecoration={props.noDecoration}
    >
      {props.headerImage && (
        <HeaderImage imageUrl={props.headerImage} headerOpacity={props.headerOpacity} />
      )}
      {props.title && <div className="Panel_Title">{props.title}</div>}
      <div className="Panel_Body">{props.children}</div>
    </PanelWrapper>
  )
}

const HeaderImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 200px;
  z-index: -1;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: ${({ headerOpacity }) => (headerOpacity ? 0.4 : 1)};
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    height: 100px;
  }
`
const PanelWrapper = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${COLORS.white};
  ${({ noDecoration }) => !noDecoration && `border: 1px solid ${COLORS.tertiary}`};
  ${({ noDecoration }) => !noDecoration && `box-shadow: ${COLORS.cardShadow}`};
  border-radius: 10px;
  padding: 20px 80px;
  z-index: 1;
  &.dashboard {
    padding: 20px 80px;
    @media screen and (max-width: ${BREAKPOINT_DESKTOP}px) {
      padding: 20px 50px;
    }
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      padding: 16px;
    }
    .Panel_Title {
      padding: 12px 0 0;
    }
  }

  @media screen and (max-width: ${BREAKPOINT_DESKTOP}px) {
    padding: 20px 50px;
  }

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    padding: 0;
    border: none;
    box-shadow: none;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin: 0;
  }

  .Panel_Title {
    max-width: 1080px;
    font-size: 22px;
    font-weight: 700;
    padding: 12px 0 8px;
    margin: 0 auto;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      padding: 0;
      margin: 0 auto;
    }
  }

  .Panel_Body {
    max-width: 1080px;
    margin: 0 auto;
    padding: 24px 0;

    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      height: calc(100% - 32px);
      padding: 20px 0 16px;
      margin: 0 auto 14px;
    }
  }
`

export default Panel
