import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'
import InputLabel from './InputLabel'
import SVG from 'react-inlinesvg'

interface IProps {
  name: string
  defaultChecked?: boolean
  value?: string | number
  label?: string
  showLabelInline?: boolean
  error?: null | string
  singleCheckBox?: boolean
  readonly?: boolean
  onChangeHandler?(event: any): void
  checked?: boolean
}

const CheckBox: React.FC<IProps> = props => {
  return (
    <CheckBoxWrapper className="CheckBox" singleCheckBox={props.singleCheckBox}>
      <div className="CheckBox_Wrapper">
        {props.label && !props.showLabelInline && <InputLabel label={props.label} />}
        <div className="CheckBox_Main">
          <label className={props.readonly ? 'readonly' : ''}>
            <input
              id={props.name}
              type="checkbox"
              name={props.name}
              value={props.value}
              defaultChecked={ props.defaultChecked}
              checked={ props.checked }
              onChange={event => {
                if (typeof props.onChangeHandler === 'function' && !props.readonly) {
                  props.onChangeHandler(event)
                }
              }}
            />
            <span className="CheckBox_Icon">
              <SVG
                src="/images/icons/check_box.svg"
                className="icon filled"
                aria-hidden="true"
                role="presentation"
              />
              <SVG
                src="/images/icons/check_box_outline_blank.svg"
                className="icon outline"
                aria-hidden="true"
                role="presentation"
              />
            </span>
            {props.showLabelInline && <span>{props.label}</span>}
          </label>
        </div>
      </div>
      {props.error && <span className="CheckBox_Error">{props.error}</span>}
    </CheckBoxWrapper>
  )
}

const CheckBoxWrapper = styled.div`
  ${({ singleCheckBox }) => singleCheckBox && `display: flex;`}
  .CheckBox_Wrapper {
    display: flex;
  }

  .CheckBox_Main {
    display: flex;
    align-items: center;
    flex: 1;

    > label {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
      &.readonly {
        pointer-events: none;
        color: ${constants.COLORS.disabled};
      }
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        font-size: 12px;
      }
      &:hover {
        opacity: 0.75;
      }

      input {
        opacity: 0;
        height: 0;
        width: 0;
      }

      .CheckBox_Icon {
        margin-right: 8px;
        font-size: 22px;
        @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
          font-size: 18px;
          ${({ singleCheckBox }) => singleCheckBox && `margin-bottom: 12px;`}
        }
        .icon {
          &.filled {
            display: none;
            color: ${constants.COLORS.primary};
          }

          &.outline {
            display: block;
            color: ${constants.COLORS.divider};
          }
        }
      }

      input:checked + .CheckBox_Icon {
        .icon {
          &.filled {
            display: block;
          }

          &.outline {
            display: none;
          }
        }
      }

      span {
        font-size: 14px;
      }
    }
  }

  .CheckBox_Error {
    display: block;
    margin-top: 8px;
    color: ${constants.COLORS.cautionText};
    font-size: 14px;
  }

  & + & {
    margin-left: 22px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      margin-left: 0;
      margin-top: 8px;
    }
  }
`

export default CheckBox
