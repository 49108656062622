import * as React from 'react'
import styled from 'styled-components'
import { IsMobile, IsTabletOrDesktop } from 'utils/responsive'
import * as constants from '../../static/constants'
import Button from './Button'
import Typography from './_layouts/Typography'
import { pushDataLayer } from 'utils/googleTagManager'

interface IWindow {
  globalModal: GlobalModal
}
declare var window: IWindow

interface IState {
  showModal: boolean
  title?: string
  body?: any
  closeText?: string
  submitText?: string
  handleSubmit?: any
  hideCloseIcon?: boolean
}

const initialState = {
  title: '',
  showModal: false,
  body: null,
  closeText: '',
  submitText: '',
  handleSubmit: null,
  hideCloseIcon: false,
}

export default class GlobalModal extends React.PureComponent<{}, IState> {
  constructor(props) {
    super(props)

    this.state = { ...initialState }
    window.globalModal = this
  }

  public showModal = (config: Omit<IState, 'showModal'>) => {
    this.setState({
      showModal: true,
      body: config.body ?? undefined,
      ...config,
    })
    document.body.style.overflow = 'hidden'
    const eventData = { event: 'show_modal', modal_title: config.title || '' }
    pushDataLayer(eventData)
  }

  public updateModalBody = body => {
    this.setState({ body })
    const eventData = { event: 'update_modal_body' }
    pushDataLayer(eventData)
  }

  public closeModal = () => {
    this.setState({ ...initialState })
    document.body.style.overflow = 'unset'
    // console.log('close')
    const eventData = { event: 'close_modal' }
    pushDataLayer(eventData)
  }

  public closeModal2 = () => {
    this.setState({ showModal: false })
    document.body.style.overflow = 'unset'
    // console.log('close')
  }

  public render() {
    return (
      <>
        {this.state.showModal && (
          <Modal className="Modal">
            <div className="Modal_Inner">
              {this.state.title && (
                <div className="Modal_Header">
                  {!this.state.hideCloseIcon && (
                    <span className="Modal_Close" onClick={this.closeModal2}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                      </svg>
                    </span>
                  )}
                  <IsTabletOrDesktop>
                    <Typography type="headline" size="lg">
                      {this.state.title}
                    </Typography>
                  </IsTabletOrDesktop>
                  <IsMobile>
                    <Typography type="headline" size="sm">
                      {this.state.title}
                    </Typography>
                  </IsMobile>
                </div>
              )}
              <div className="Modal_Body">{this.state.body}</div>
              {(this.state.closeText || this.state.handleSubmit) && (
                <div className="Modal_Footer">
                  {this.state.closeText && (
                    <Button id="Modal_Close" handleClick={this.closeModal}>
                      {this.state.closeText}
                    </Button>
                  )}
                  {this.state.handleSubmit && (
                    <Button
                      id="Modal_Submit"
                      textColor={constants.COLORS.white}
                      backgroundColor={constants.COLORS.primary}
                      handleClick={this.state.handleSubmit}
                    >
                      {this.state.submitText}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </Modal>
        )}
      </>
    )
  }
}

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.4s;
  transform: translate3d(0, 100%, 0);
  transform: none;
  background-color: ${constants.COLORS.modalBackground};
  z-index: 6000;

  .Modal_Inner {
    position: relative;
    width: 840px;
    max-width: 100%;
    max-height: calc(100dvh - 100px);
    padding: 52px 58px;
    margin: 0 16px;

    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid ${constants.COLORS.tertiary};
    border-radius: 10px;
    background: ${constants.COLORS.white};
    box-shadow: ${constants.COLORS.cardShadow};

    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      height: calc(100% - 100px);
      height: 100dvh;
      max-height: none;
      margin: 0;
      padding: 16px;
      border: none;
      border-radius: 0;
    }
  }

  .Modal_Header {
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 56px;
      background-color: ${constants.COLORS.white};
      border-bottom: 1px solid ${constants.COLORS.divider};
      z-index: 1000;
    }
  }

  .Modal_Body {
    padding-top: 24px;
    overflow: hidden;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      padding-top: 16px;
      margin: 40px 0 0;
    }
    .Panel .Panel {
      padding-left: 0;
      padding-right: 0;
      @media (min-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        .Panel_Body {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .Modal_Footer {
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
  }

  .Modal_Close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: ${constants.COLORS.cardBG};
    }

    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      top: 12px;
      left: 12px;
    }
  }

  .AddReply_Editor {
    margin-top: -32px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      margin-top: 0;
    }
    .Avatar {
      margin-bottom: 24px;
    }
  }

  .AddReply_Footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
  }
`
